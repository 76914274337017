<template>
  <div class="content-wrapper">
    <h1>Landing Builder</h1>
    <div class="social-share user-page">
      <b-card>
        <b-card-title
          >Lead Generation
          <InfoPopover class="ml-1" text="Generate custom landing pages" />
          <view-lang-select
            v-model="view_lang"
            :options="languages"
            class="float-right"
          />
        </b-card-title>
        <!-- <b-card-sub-title class="mb-2"
        >Genera nuove landing page ad HOC....</b-card-sub-title
      > -->
        <b-row>
          <b-col md="9" xl="7">
            <div
              class="
              square
              button-dashed
              d-flex
              flex-column
              justify-content-center
              align-items-center
              mt-5
            "
              v-b-toggle.sidebar-landing
            >
              <h1 class="mt-3 mb-2">+</h1>
              <h6 class="m-0">Add Landing</h6>
            </div>
            <draggable
              :list="tree"
              handle=".kpi-handle"
              @change="postNewPosition"
              ghost-class="ghost-item"
            >
              <div
                v-for="landing in tree"
                :key="landing.id"
                class="d-flex align-items-center mt-3"
              >
                <img
                  class="square img-fit flex-shrink-0 mr-4"
                  :src="landing.url_profile"
                />

                <div>
                  <h4>{{ landing.title[view_lang] }}</h4>
                </div>
                <div class="ml-auto p-2 pl-3 h4 text-muted">
                  <b-icon
                    icon="arrows-move"
                    class="pointer mr-2 kpi-handle"
                  ></b-icon>
                  <b-icon
                    icon="gear-fill"
                    class="pointer mr-2"
                    v-b-toggle.sidebar-landing
                    @click="
                      currentLanding = landing
                      tmpLanding = JSON.parse(JSON.stringify(landing))
                    "
                  ></b-icon>
                  <b-icon
                    icon="trash-fill"
                    class="pointer"
                    @click="deleteCheck(landing.id)"
                  ></b-icon>
                </div>
              </div>
            </draggable>
          </b-col>
        </b-row>
      </b-card>

      <div class="content-wrapper" v-if="hasNextRoute()">
        <b-button squared variant="primary" size="lg" @click="toNextRoute()"
          >Save and continue</b-button
        >
      </div>

      <b-button
        v-if="isDebug"
        class="fixed-button py-2"
        v-b-toggle.sidebar-iframe
      >
        <img src="../assets/images/phone.png" class="img-fluid" />
      </b-button>

      <LandingPageEditor
        :languages="languages"
        :default_lang="default_lang"
        :idCampaign="idCampaign"
        :landing="tmpLanding"
        @add-landing="addLanding"
        @edit-landing="editLanding"
        @reset-landing="
          currentLanding = null
          tmpLanding = null
        "
      />

      <b-modal
        v-model="showDeleteModal"
        title="Attention!"
        ok-title="Confirm"
        cancel-title="Cancel"
        @hidden="landingDeleteId = null"
        @ok="deleteItem(landingDeleteId)"
        >Do you really want to delete the selected element?</b-modal
      >

      <b-modal
        v-model="showSocialDeleteModal"
        title="Attention!"
        ok-title="Confirm"
        cancel-title="Cancel"
        @hidden="socialType = ''"
        @ok="deleteSocialItem()"
        >Do you really want to delete the selected element?</b-modal
      >
    </div>
  </div>
</template>

<script>
import LandingPageEditor from "@/components/LandingPageEditor.vue"
import draggable from "vuedraggable"

import AxiosService from "@/axiosServices/AxiosService.js"
import * as CampaignRoutes from "@/utils/CampaignRoutes.js"

// import FilesUploadManager from "@/components/FilesUploadManager.vue"

export default {
  components: {
    LandingPageEditor,
    draggable,
    // FilesUploadManager,
  },
  data() {
    return {
      campaignName: "",

      documentiCaricati: [],

      isDebug: null,
      idCampaign: 0,
      landing_list: [],
      tree: [],
      landingDeleteId: null,
      showDeleteModal: false,
      currentLanding: null,
      tmpLanding: null,

      //FB
      showFbTutorialModal: false,
      slide: 0,
      showFacebookModal: false,
      FB: {},
      model: {},
      scope: {},
      fb_pages: [],
      fb_page_id: null,
      fb_me: null,
      fb_socialShare: {},

      //linkedin
      showLinkedinTutorialModal: false,
      slide2: 0,
      linkedin_socialShare: {},
      linkedin_pages: [],
      showLinkedinModal: false,

      socialType: "",
      showSocialDeleteModal: false,

      isNewCampaign: this.$route.params.campaignId == null,

      url_demo: "",
      usersList: [{ value: null, text: "Seleziona Utente" }],
      userSelected: null,

      view_lang: null,
    }
  },
  service: null,
  campaignService: null,
  created() {
    this.service = new AxiosService("Landing")
    this.campaignService = new AxiosService("Campaign")

    this.view_lang = this.default_lang
  },
  mounted() {
    // if (!this.isNewCampaign) {
    //   this.getUsersByCampaign()
    // }

    let params = [{ param: "idCampagna", value: this.idCampaign }]
    this.service.read(params).then((res) => {
      res.forEach((element) => {
        this.landing_list.push(element)
      })
      this.tree = this.buildTree(this.landing_list)
    })
  },
  methods: {
    getUsersByCampaign() {
      this.usersList.splice(1)
      if (this.$route.params.campaignId) {
        this.service
          .readCustomEndpoint(
            "CampaignManager/GetUsersInCampaign",
            this.$route.params.campaignId
          )
          .then((res) => {
            res.forEach((element) => {
              this.usersList.push(element)
            })
          })
      } else {
        console.log("FAIL ")
      }
    },
    getUrlDemo() {
      if (this.userSelected != null) {
        this.service.readCustomEndpoint("Setting").then((res) => {
          if (res) {
            this.url_demo =
              res.url_demo +
              "/debug/" +
              this.$route.params.campaignId +
              "/" +
              this.userSelected +
              "/socialshare/" +
              this.campaignName
          } else {
            console.err("ERROr calling Setting")
          }
        })
      } else {
        this.url_demo = ""
        // this.service.readCustomEndpoint("Setting").then((res) => {
        //   this.url_demo = res.url_demo + "login";
        // });
      }
    },
    resetDataSidebarIframe() {
      this.userSelected = null
      this.url_demo = ""
    },

    addLanding(item) {
      this.landing_list.unshift(item)
      this.tree = this.buildTree(this.landing_list)
    },
    editLanding(item) {
      Object.assign(this.currentLanding, item)
    },
    deleteCheck(id) {
      this.landingDeleteId = id
      this.showDeleteModal = true
    },
    deleteItem(id) {
      this.service
        .delete(id)
        .then((response) => {
          if (response) {
            this.landing_list = this.landing_list.filter(
              (currentItem) => id !== currentItem.id
            )
            this.tree = this.buildTree(this.landing_list)
            this.$successToast()
          } else {
            this.$errorToast()
            return false
          }
        })
        .catch(() => {
          this.$errorToast()
          return false
        })
    },
    buildTree() {
      let list = JSON.parse(JSON.stringify(this.landing_list))
      if (!list) return []
      let map = {},
        node,
        roots = [],
        i
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i // initialize the map
        list[i].children = [] // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.id_padre && node.id_padre !== "0") {
          if (Object.prototype.hasOwnProperty.call(map, node.id_padre)) {
            list[map[node.id_padre]].children.push(node)
          }
          //else nodo orfano
        } else {
          roots.push(node)
        }
      }
      return roots
    },
    flattenTree(children, parent) {
      return Array.prototype.concat.apply(
        children.map((x) => {
          x.id_padre = parent || 0
          return x
        }),
        children.map((x) => this.flattenTree(x.children || [], x.id))
      )
    },
    postNewPosition() {
      let newOrder = []
      for (let i = 0; i < this.tree.length; i++) {
        newOrder.push({ ID: this.tree[i].id, Ordine: i })
      }
      this.service
        .updateOrder(newOrder)
        .then(() => {
          this.$successToast()
        })
        .catch(() => {
          this.$errorToast()
          return false
        })
    },
    handleSdkInit({ FB, scope }) {
      this.FB = FB
      this.scope = scope
    },
    FB_loadPagesList() {
      if (!this.fb_me) {
        this.fb_me = this.FB.getAuthResponse()
        /*this.FB.api(
          "/oauth/access_token?grant_type=fb_exchange_token&client_id=584563499070024&client_secret=fa75e656a16309444ea812dd8eb604c1&fb_exchange_token=" +
            this.fb_me.accessToken,
          this.FB_setLongTermToken
        );*/
      }
      this.FB.api("me/accounts?type=page", this.FB_loadHandler)
    },
    /*FB_setLongTermToken(tokenObject) {
      console.log(tokenObject);
      console.log(this.FB.getAuthResponse());
      //this.fb_me.accessToken = token;
    },*/
    FB_loadHandler(response) {
      this.fb_pages = response.data
      if (this.fb_pages.length) {
        this.fb_page_id = this.fb_pages[0].id
      } else {
        this.fb_page_id = null
      }
    },
    loadFacebookObject() {
      this.service
        .readCustomEndpoint(
          "SocialShare/GetBackofficeFacebookLogin",
          this.idCampaign
        )
        .then((res) => {
          this.fb_socialShare = res
        })
    },
    saveFacebook() {
      if (this.fb_me && this.fb_page) {
        let fb_object = {
          accessToken: this.fb_me.accessToken,
          expiresIn: this.fb_me.expiresIn,
          pageAccessToken: this.fb_page.access_token,
          pageID: this.fb_page.id,
          pageName: this.fb_page.name,
        }
        this.service
          .postCustomEndpoint(
            "SocialShare/SaveBackofficeFacebookLogin",
            this.idCampaign,
            fb_object
          )
          .then(() => {
            this.fb_socialShare = fb_object
            this.$successToast()
          })
          .catch(() => {
            this.$errorToast()
          })
      } else {
        this.service
          .putCustomEndpoint(
            "SocialShare/UpdateBackofficeFacebookLogin",
            this.idCampaign,
            this.fb_socialShare
          )
          .then(() => {
            this.$successToast()
          })
          .catch(() => {
            this.$errorToast()
            this.loadFacebookObject()
          })
      }
    },
    deleteSocialCheck(type) {
      this.socialType = type
      this.showSocialDeleteModal = true
    },
    deleteSocialItem() {
      let url = ""
      let callback = () => {}
      if (this.socialType == "fb") {
        url = "SocialShare/DeleteBackofficeFacebookLogin"
        callback = () => {
          this.fb_socialShare = {}
        }
      }
      if (this.socialType == "linkedin") {
        url = "SocialShare/DeleteBackofficeLinkedinLogin"
        callback = () => {
          this.linkedin_socialShare = {}
        }
      }

      if (url) {
        this.service
          .deleteCustomEndpoint(url, this.idCampaign)
          .then((response) => {
            if (response) {
              callback()
              this.$successToast()
            } else {
              this.$errorToast()
              return false
            }
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
          .finally(() => {
            this.socialType = ""
          })
      }
    },
    loadLinkedinObject() {
      this.service
        .readCustomEndpoint(
          "SocialShare/GetBackofficeLinkedinLogin",
          this.idCampaign
        )
        .then((res) => {
          if (res.pageID) {
            this.linkedin_socialShare = res
          }
        })
    },
    linkedinManager() {
      console.log(this.linkedin_socialShare)
      if (!this.linkedin_socialShare.accessToken) {
        let w = 500
        let h = 600
        let left = screen.width / 2 - w / 2
        let top = screen.height / 2 - h / 2
        const host = window.location.hostname
        let cleanedHost = host.replace(/[^a-z0-9._-]/gi, "").toLowerCase()
        if (cleanedHost == "localhost") cleanedHost = "localhost:8080"
        let redirect_uri = "https://" + cleanedHost + "/linkedin"
        window.open(
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77pvhpfr6dieg3&redirect_uri=" +
            redirect_uri +
            "&state=ede1ba59587fdba1f738ec9701e2c632&scope=r_liteprofile%20r_emailaddress%20w_member_social%20r_organization_social%20rw_organization_admin",
          "popup",
          "width=" + w + ",height=" + h + ",top=" + top + ",left=" + left
        )
      } else {
        this.showLinkedinModal = true
      }
    },
    setLinkedin(code) {
      let linkedin_object = {
        accessToken: code,
        expiresIn: 0,
        pageAccessToken: "",
        pageID: "",
        pageName: "",
        target: 0,
      }
      this.service
        .postCustomEndpoint(
          "SocialShare/SaveBackofficeLinkedinLogin",
          this.idCampaign,
          linkedin_object
        )
        .then((list) => {
          this.linkedin_socialShare = linkedin_object
          this.linkedin_pages = list
          this.showLinkedinModal = true
          this.$successToast()
        })
        .catch(() => {
          this.$errorToast()
        })
    },
    saveLinkedin() {
      if (!this.linkedin_socialShare.pageID) {
        this.linkedin_socialShare.pageID = this.linkedin_page.id
        this.linkedin_socialShare.pageName = this.linkedin_page.Name
      }
      this.service
        .putCustomEndpoint(
          "SocialShare/UpdateBackofficeLinkedinLogin",
          this.idCampaign,
          this.linkedin_socialShare
        )
        .then(() => {
          this.$successToast()
        })
        .catch(() => {
          this.$errorToast()
          this.loadLinkedinObject()
        })
    },
    hasNextRoute() {
      return CampaignRoutes.hasNextRoute(this, "SocialShare")
    },
    toNextRoute() {
      CampaignRoutes.toNextRoute(this, "SocialShare")
    },
  },
  computed: {
    fb_page() {
      if (this.fb_page_id) {
        return this.fb_pages.find((x) => x.id === this.fb_page_id) || null
      } else {
        return null
      }
    },
    linkedin_page() {
      if (this.linkedin_page_id) {
        return (
          this.linkedin_pages.find((x) => x.id === this.linkedin_page_id) ||
          null
        )
      } else {
        return null
      }
    },
    languages() {
      return this.$parent.companyLanguages
    },
    default_lang() {
      return ["it-IT"]
    },
  },
}
</script>
